interface Props {
  id: number;
  title: string;
  currentSelectedId: number;
  setHomeText: (projectId: number) => void;
}

export default function ProjectListItem({
  id,
  title,
  setHomeText,
  currentSelectedId,
}: Props) {
  const thisId = id;
  const isSelected = thisId === currentSelectedId;

  return (
    <button
      className={`flex transition ease-in-out hover:font-medium hover:scale-110 w-fit ${
        isSelected ? "font-medium scale-110" : ""
      }`}
      onClick={() => setHomeText(thisId)}
    >
      <p>{title}</p>
      {isSelected && <span className="w-6 text-slate-300"> &#8718; </span>}
    </button>
  );
}
