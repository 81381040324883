import { motion } from "framer-motion";

interface Props {
  images: HTMLImageElement[];
  setModalImageIndex: (index: number) => void;
}

const animation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export default function HomeImages({ images, setModalImageIndex }: Props) {
  return (
    <div className="flex flex-row gap-2 min-h-[64px]">
      {images?.map((image, index) => (
        <button
          key={image.src}
          type="button"
          onClick={() => setModalImageIndex(index)}
        >
          <motion.img
            initial="hidden"
            variants={animation}
            animate="visible"
            src={image.src}
            alt="idk"
            className="rounded-md object-cover w-36 h-20"
          />
        </button>
      ))}
    </div>
  );
}
