import { AnimationControls } from "framer-motion";
import HomeText from "./HomeText";
import HomeTitle from "./HomeTitle";

interface Props {
  currentTitle: string;
  currentText: string;
  currentSecondaryText: string;
  control: AnimationControls;
}

export default function HomeView({
  currentTitle,
  currentText,
  currentSecondaryText,
  control,
}: Props) {
  return (
    <div className="flex flex-col sm:min-h-fit flex-2 mt-6 sm:mt-0 sm:justify-center gap-y-2 sm:max-h-full">
      <div className="flex items-end sm:flex-1 gap-4">
        <HomeTitle currentTitle={currentTitle} />
      </div>
      <div className="flex-[3] pl-1 flex flex-col gap-6">
        <HomeText
          currentText={currentText}
          currentSecondaryText={currentSecondaryText}
          control={control}
        />
      </div>
    </div>
  );
}
