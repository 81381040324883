import { useAnimation } from "framer-motion";
import {
  AiFillGithub as GithubIcon,
  AiFillLinkedin as LinkedInIcon,
} from "react-icons/ai";
import { MdMail as MailIcon } from "react-icons/md";
import { FaItchIo as ItchIoIcon } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getImages, getPageText, getProjects } from "../../services/firebase";
import Project from "../../models/Project";
import ProjectListItem from "../../components/project/ProjectListItem";
import TopLeftTriangle from "../../components/triangles/TopLeftTriangle";
import BottomRightTriangle from "../../components/triangles/BottomRightTriangle";
import Logo from "../Logo";
import NavButton from "../../components/buttons/NavButton";
import ImageModalPanel from "../../components/ImageModalPanel";
import ProjectView from "../../components/project/ProjectView";
import HomeView from "../../components/home/HomeView";
import Images from "../../models/Images";

export default function Home() {
  // Fixa en state variabel på home text + home title som vi skickar in i varje knapp.
  const [homeTitle, setHomeTitle] = useState("Hello");
  const [homeText, setHomeText] = useState("");
  const [homeSecondaryText, setHomeSecondaryText] = useState("");

  const [currentTitle, setCurrentTitle] = useState("Hello");
  const [currentText, setCurrentText] = useState("");
  const [currentSecondaryText, setCurrentSecondaryText] = useState("");
  const [currentImages, setCurrentImages] = useState<HTMLImageElement[]>([]); // [0] = main image, [1] = secondary image
  const [projectButtonIndex, setProjectButtonIndex] = useState(-1);

  const [projects, setProjects] = useState<Project[]>();

  /* Modal Image state */
  const [modalImageIndex, setModalImageIndex] = useState(-1);
  const [allImages, setAllImages] = useState<Images[]>([]); // This is a list of a list of images.

  /* Mobile page state */
  const [menuOpen, setMenuOpen] = useState(false);
  const setHomePage = (projectId: number) => {
    if (projects === undefined) return;
    if (projectId === projectButtonIndex) {
      setHomePageDefault();
      return;
    }
    setProjectButtonIndex(projectId);
    setCurrentTitle(projects[projectId].title);
    setCurrentText(projects[projectId].description);
    setCurrentSecondaryText(projects[projectId].stack);

    setCurrentImages(allImages[projectId].value);

    setMenuOpen(false);
  };

  const setHomePageDefault = () => {
    setCurrentTitle(homeTitle);
    setCurrentText(homeText);
    setCurrentSecondaryText(homeSecondaryText);
    setProjectButtonIndex(-1);
    setCurrentImages([]);
  };

  const handleOnClickHome = () => {
    setMenuOpen(false);
    setHomePageDefault();
  };

  const handleOnClickProjects = () => {
    setMenuOpen(true);
    setHomePageDefault();
  };

  /* This will only be run at the start of the component */
  useEffect(() => {
    getPageText("about").then((data) => {
      setHomeText(data[0].value);
      setCurrentText(data[0].value);
    });
    getPageText("occupation").then((data) => {
      setHomeSecondaryText(data[0].value);
      setCurrentSecondaryText(data[0].value);
    });
  }, []);

  useEffect(() => {
    getProjects().then((data) =>
      setProjects(
        data.map(
          (project, index): Project => ({
            id: index,
            title: project.name,
            description: project.description,
            stack: project.stack,
            star: project.star,
            website: project.website,
            youtube: project.youtube,
            github: project.github,
            imageFolder: project.images,
          })
        )
      )
    );
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      if (projects === undefined) return;

      const imageList = await Promise.all(
        projects.map(async (project) => {
          const images: Images = {
            value: [],
          };

          images.value = await getImages(project.imageFolder).then((data) => {
            return data.map((imageURL) => {
              const imgElement = new Image();
              imgElement.src = imageURL;
              return imgElement;
            });
          });

          return images;
        })
      );
      setAllImages(imageList);
    };

    fetchImages();
  }, [projects]);

  /*   useEffect(() => {
    if (modalImageIndex === -1) return;
  }, [modalImageIndex]); */

  /* useAnimation creates AnimationControls that can be used to manually
  start and stop animations on one or more components */
  const control = useAnimation();

  useEffect(() => {
    control.set("hidden");
    control.start("visible");
  }, [currentText, currentTitle, menuOpen]);

  return (
    /* OUTER CONTAINER */
    <div className="flex flex-col sm:min-h-[650px] min-h-screen sm:h-screen w-full items-center sm:justify-center bg-bgPrimary">
      {modalImageIndex > -1 && (
        <ImageModalPanel
          image={currentImages[modalImageIndex]}
          currentIndex={modalImageIndex}
          maxIndex={currentImages.length - 1}
          setModalImageIndex={setModalImageIndex}
        />
      )}
      <div className="flex w-full h-fit sm:min-h-[350px] sm:h-full sm:max-h-[700px] max-w-[1080px]">
        <div className="relative w-full mr-[-4px] sm:h-full bg-bgSecondary rounded-xl sm:shadow-bg">
          <TopLeftTriangle />
          <BottomRightTriangle />
          <div className="static sm:absolute bottom-8 top-0 right-0 left-0 ">
            <div className="flex flex-col h-fit sm:h-full m-4 px-4 py-1 rounded-xl bg-bgSecondary z-10">
              {/* HEADER */}
              <div className="flex justify-between h-14 items-center m-1 sm:m-2">
                <Logo onButtonClick={setHomePageDefault} />
                <div>
                  <div className="grid grid-flow-col place-items-center h-full gap-4">
                    <NavButton
                      link="https://www.linkedin.com/in/pouyashirin/"
                      icon={LinkedInIcon}
                    />
                    <NavButton
                      link="https://github.com/Kexon"
                      icon={GithubIcon}
                    />
                    <NavButton
                      link="https://kexon.itch.io/"
                      icon={ItchIoIcon}
                    />
                    <NavButton link="mailto:Kexon@hotmail.se" icon={MailIcon} />
                  </div>
                </div>
              </div>
              {/* BODY */}
              {!menuOpen ? (
                <div className="flex flex-col sm:flex-row sm:h-[calc(100%-100px)] min-h-[350px] gap-4 mx-2 sm:pl-8">
                  {/* LEFT SIDE */}
                  {projectButtonIndex > -1 &&
                  projects?.[projectButtonIndex] !== undefined ? (
                    <ProjectView
                      project={projects?.[projectButtonIndex]}
                      setModalImageIndex={setModalImageIndex}
                    />
                  ) : (
                    <HomeView
                      currentTitle={currentTitle}
                      currentText={currentText}
                      currentSecondaryText={currentSecondaryText}
                      control={control}
                    />
                  )}

                  {/* RIGHT SIDE */}
                  <div className="flex-1 sm:flex flex-col hidden sm:flex-row justify-center items-center h-[70%] gap-1 sm:gap-8">
                    <div className="sm:bg-gradient-to-b from-bgSecondary via-slate-600 to-bgSecondary h-1 w-36 sm:h-64 sm:mt-10 sm:w-1 justify-center items-center bg-gradient-to-r "></div>
                    <div className="flex flex-col h-fit pt-4 w-fit">
                      <div className="flex sm:flex-1 sm:h-full justify-center sm:items-end mb-2">
                        <p className="text-primaryText text-3xl text-center font-medium">
                          Projects
                        </p>
                      </div>
                      <div className="flex sm:flex-[3] flex-col w-44 self-center whitespace-nowrap items-start gap-1 text-slate-300 pl-10">
                        {projects?.map((project) => (
                          <ProjectListItem
                            key={project.id}
                            id={project.id}
                            title={project.title}
                            currentSelectedId={projectButtonIndex}
                            setHomeText={setHomePage}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col sm:h-[calc(100%-100px)] min-h-[350px] ">
                  <div className="flex flex-col justify-center items-center h-[70%] gap-1 pt-6">
                    <div className="flex flex-col h-fit pt-4 w-fit">
                      <div className="flex sm:flex-1 sm:h-full justify-center sm:items-end mb-2">
                        <p className="text-primaryText text-5xl text-center font-medium">
                          Projects
                        </p>
                      </div>
                      <div className="flex flex-col w-44 self-center whitespace-nowrap text-2xl items-start gap-1 text-slate-300 pl-6 mt-4">
                        {projects?.map((project) => (
                          <ProjectListItem
                            key={project.id}
                            id={project.id}
                            title={project.title}
                            currentSelectedId={projectButtonIndex}
                            setHomeText={setHomePage}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* FOOTER */}
              <footer className="hidden sm:flex h-12 pl-2 mt-8 sm:mt-0 text-right justify-between items-center text-slate-400">
                <p></p>
                <p>kexon@hotmail.se ©2023</p>
              </footer>
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE MENU */}
      <div className="fixed sm:hidden bottom-6 left-[calc(50%-128px)] right-1/4 h-12 w-64">
        <div className="flex w-full h-full ">
          <button
            className={`h-full text-2xl w-full ${
              !menuOpen && projectButtonIndex === -1
                ? "bg-slate-800 text-slate-700"
                : "bg-slate-600 text-primaryText shadow-left"
            } rounded-xl rounded-r-none`}
            onClick={handleOnClickHome}
          >
            <p className="text-center w-full">Home</p>
          </button>
          <button
            className={`h-full text-xl w-full ${
              menuOpen
                ? "bg-slate-800 text-slate-700"
                : "bg-slate-600 text-primaryText shadow-right"
            } rounded-xl rounded-l-none`}
            onClick={handleOnClickProjects}
          >
            <p className="text-center w-full">Projects</p>
          </button>
        </div>
      </div>
      {/* <div className="absolute sm:hidden bottom-0 top-0 left-1/4 right-0 bg-slate-400"></div> */}
    </div>
  );
}
