import Project from "../../models/Project";
import ProjectButton from "../buttons/ProjectButton";
import {
  AiFillGithub as GithubIcon,
  AiFillLinkedin as LinkedInIcon,
  AiFillHome as HomeIcon,
  AiFillYoutube as YoutubeIcon,
} from "react-icons/ai";
import HomeText from "../home/HomeText";
import HomeImages from "../HomeImages";
import { useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { getImages } from "../../services/firebase";

interface Props {
  project: Project;
  setModalImageIndex: (index: number) => void;
}

export default function ProjectView({ project, setModalImageIndex }: Props) {
  const [currentImages, setCurrentImages] = useState<HTMLImageElement[]>([]);

  /* useAnimation creates AnimationControls that can be used to manually
    start and stop animations on one or more components */
  const control = useAnimation();

  useEffect(() => {
    if (project.imageFolder === undefined) {
      setCurrentImages([]);
    } else {
      getImages(project.imageFolder).then((data) => {
        var images = data.map((imageURL) => {
          const imgElement = new Image(); // Create a new HTMLImageElement instance
          imgElement.src = imageURL; // Set the src property
          return imgElement; // Return the created element
        });
        setCurrentImages(images);
      });
    }

    control.set("hidden");
    control.start("visible");
  }, [project]);
  return (
    <div className="flex flex-col sm:min-h-fit flex-2 mt-6 mb-16 sm:mt-0 sm:justify-center gap-y-2 sm:max-h-full">
      <div className="flex items-end sm:flex-1 gap-4">
        <p className="text-slate-300 text-4xl sm:text-5xl text-left">
          {project.title}
        </p>
        <div className="flex flex-1 justify-center sm:justify-start h-10">
          {project.website && (
            <ProjectButton link={project.website} icon={HomeIcon} />
          )}
          {project.youtube && (
            <ProjectButton link={project.youtube} icon={YoutubeIcon} />
          )}
          {project.github && (
            <ProjectButton link={project.github} icon={GithubIcon} />
          )}
        </div>
      </div>
      <div className="flex-[3] pl-1 flex flex-col gap-6">
        <HomeText
          currentText={project.description}
          currentSecondaryText={project.stack && "Stack: " + project.stack}
          control={control}
        />
        {project.imageFolder && (
          <HomeImages
            images={currentImages}
            setModalImageIndex={setModalImageIndex}
          />
        )}
      </div>
    </div>
  );
}
