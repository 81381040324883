import { initializeApp } from "firebase/app";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore/lite";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAHmxfyCsorIuvr5wbzTaPHOYucjGsWAD0",
  authDomain: "personal-website-b2c55.firebaseapp.com",
  projectId: "personal-website-b2c55",
  storageBucket: "personal-website-b2c55.appspot.com",
  messagingSenderId: "226817194606",
  appId: "1:226817194606:web:b90a05943192f12875c47f",
  measurementId: "G-LFEVQ7ZKZD",
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export async function getProjects() {
  const projectsCollection = collection(db, "projects"); // This gets the jobs collection
  const queriedData = query(projectsCollection); // This gets the jobs collection
  //const jobsSnapshot = await getDocs(jobsCol); // This gets the documents in the jobs collection
  const projectsSnapshot = await getDocs(queriedData); // This gets the documents in the jobs collection
  const projectList = projectsSnapshot.docs.map((doc) => doc.data()); // This gets the data from the documents
  //const test = orderBy("startYear", "desc"); // This orders the jobs collection by startYear in descending order
  return projectList;
}

export async function getJobs() {
  const jobsCol = collection(db, "jobs"); // This gets the jobs collection
  const queriedData = query(jobsCol, orderBy("endyear", "desc")); // This gets the jobs collection and orders it by startYear
  //const jobsSnapshot = await getDocs(jobsCol); // This gets the documents in the jobs collection
  const jobsSnapshot = await getDocs(queriedData); // This gets the documents in the jobs collection
  const jobList = jobsSnapshot.docs.map((doc) => doc.data()); // This gets the data from the documents
  //const test = orderBy("startYear", "desc"); // This orders the jobs collection by startYear in descending order
  return jobList;
}
export async function getPageText(id: string) {
  const pagesCollection = collection(db, "pages"); // This gets the pages collection
  const queriedData = query(pagesCollection, where("id", "==", id)); // This gets the pages documents where id matches "about"
  const pagesSnapshot = await getDocs(queriedData); // This gets the documents in the pages collection (in this case only one document)
  const pagesList = pagesSnapshot.docs.map((doc) => doc.data()); // This gets the data from the documents (in this case only one document)
  return pagesList;
}

export async function getImages(folder: string) {
  const folderRef = ref(storage, folder);
  const listResult = await listAll(folderRef);
  const imagePromises = listResult.items.map(async (item) => {
    const downloadURL = await getDownloadURL(item);
    return downloadURL;
  });
  const imageList = await Promise.all(imagePromises);
  return imageList;
}

export async function getImageUrl(imageName: string) {
  let storageRef;
  /* the imagename is enough to get the path to the image in the Storage Bucket.
   * The image's storage location can also be used instead of the image name.
   * Not sure why they can be used synonomously.
   */
  if (imageName) {
    storageRef = ref(storage, imageName);
  } else {
    storageRef = ref(storage, "placeholder.png");
  }
  const url = await getDownloadURL(storageRef);
  return url;
}
