import { useState } from "react";
import { AiOutlineLeft, AiOutlineRight, AiOutlineClose } from "react-icons/ai";

interface Props {
  image: HTMLImageElement;
  currentIndex: number;
  maxIndex: number;
  setModalImageIndex: (index: number) => void;
}

export default function ImageModalPanel({
  image,
  currentIndex,
  maxIndex,
  setModalImageIndex,
}: Props) {
  const handleModalClose = () => {
    setModalImageIndex(-1);
  };

  return (
    <div className="absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-70 z-[100]">
      <div
        onClick={handleModalClose}
        className="flex items-center justify-center w-full h-full"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative bg-slate-400 rounded-2xl p-4"
        >
          {/* LEFT BUTTON */}
          {currentIndex > 0 && (
            <button
              type="button"
              onClick={() => setModalImageIndex(currentIndex - 1)}
              className="absolute transition ease-in-out top-4 bottom-4 left-4 w-20 hover:bg-black hover:bg-opacity-10 text-white"
            >
              <div className="flex bg-black bg-opacity-20 rounded-full mx-3 p-1 pr-2">
                <AiOutlineLeft size={48} />
              </div>
            </button>
          )}
          {/* RIGHT BUTTON */}
          {currentIndex < maxIndex && (
            <button
              onClick={() => setModalImageIndex(currentIndex + 1)}
              className="absolute transition ease-in-out top-4 bottom-4 right-4 w-20 hover:bg-black hover:bg-opacity-10 text-white"
            >
              <div className="flex bg-black bg-opacity-20 rounded-full mx-3 p-1 pl-2">
                <AiOutlineRight size={48} />
              </div>
            </button>
          )}
          {/* CLOSE BUTTON  */}
          <button
            type="button"
            onClick={() => setModalImageIndex(-1)}
            className="absolute transition ease-in-out top-5 right-3 text-white"
          >
            <div className="flex bg-black bg-opacity-10 hover:bg-opacity-20 rounded-full mx-3 p-1">
              <AiOutlineClose size={40} />
            </div>
          </button>
          {/* IMAGE */}
          <img src={image.src} alt="modal" className="w-[1000px]" />
        </div>
      </div>
    </div>
  );
}
