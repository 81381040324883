interface Props {
  onButtonClick: () => void;
}

export default function Logo({ onButtonClick }: Props) {
  return (
    <div className="">
      <p className="">
        <button
          className="transition ease-in-out text-left font-bold bg-gradient-to-r bg-opacity-0 from-slate-300 to-slate-400 bg-clip-text text-transparent text-3xl sm:text-5xl hover:from-slate-400 hover:to-slate-300"
          onClick={onButtonClick}
        >
          P. Shirin
        </button>
      </p>
    </div>
  );
}
