import { motion } from "framer-motion";
import { IconType } from "react-icons/lib";

interface Props {
  link: string;
  icon: IconType;
}

const buttons = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
};

export default function NavButton({ link, icon }: Props) {
  const Icon = icon;
  return (
    <motion.button
      className="font-bold text-lg text-slate-300 hover:text-slate-500"
      variants={buttons}
      initial="hidden"
      animate="visible"
      onClick={() => window.open(link)}
    >
      {/* We need an icon here */}
      <Icon size={32} />
    </motion.button>
  );
}
