import { AnimationControls, motion } from "framer-motion";

interface Props {
  currentText: string;
  control: AnimationControls;
  currentSecondaryText: string | undefined;
}

const sentence = {
  hidden: {
    opacity: 0,
    x: -5,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

export default function HomeText({
  currentText,
  control,
  currentSecondaryText,
}: Props) {
  return (
    <motion.h1
      className="flex flex-col gap-4 text-primaryText font-normal text-base"
      variants={sentence}
      initial="hidden"
      animate={control}
    >
      <p className="text-left">{currentText}</p>
      {currentSecondaryText && (
        <p className="text-left font-medium">{currentSecondaryText}</p>
      )}
    </motion.h1>
  );
}
