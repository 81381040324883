import { IconType } from "react-icons/lib";

interface Props {
  link: string;
  icon: IconType;
}

export default function NavButton({ link, icon }: Props) {
  const Icon = icon;
  return (
    <button
      className="font-bold text-lg text-slate-300 hover:text-slate-500"
      onClick={() => window.open(link)}
    >
      {/* We need an icon here */}
      <Icon size={32} />
    </button>
  );
}
